import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const routes = [
  {
    path: '',
    redirect: '/storesell'
  },
  // {
  //   path: '/workbench',
  //   name: 'featureList',
  //   component: () => import('@/views/featureList.vue'),
  //   meta: { title: '功能列表' }
  // },
  // {
  //   path: '/brandrely',
  //   name: 'brandRely',
  //   component: () => import('@/views/brandRely'),
  //   meta: { title: '品牌关联' }
  // },
  {
    path: '/storesell',
    name: 'storeSell',
    permissions: [],
    component: () => import('@/views/storeSell'),
    meta: { title: '数据统计' }
  },
  {
    path: '/storedetail',
    name: 'StoreSellDetail',
    permissions: [],
    component: () => import('@/views/storeSell/storeSellDetail.vue'),
    meta: { title: '门店详情' }
  },
  {
    path: '/orderdetail',
    name: 'OrderDetail',
    permissions: [],
    component: () => import('@/views/storeSell/orderDetail.vue'),
    meta: { title: '订单详情' }
  },
  {
    path: '/unauthorize',
    name: 'unauthorize',
    component: () => import('@/views/unauthorize.vue'),
    meta: { title: '暂无权限' }
  },
  {
    path: '/checkdevice',
    name: 'checkdevice',
    component: () => import('@/views//checkDevice/checkDevice.vue'),
    meta: { title: '' }
  }
]
const router = new Router({
  mode: 'history',
  routes: routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
    if (to.path === '/storedetail') {
      document.title = to.query.shop_name || to.meta.title
    }
  }
  next()
})

export default router
