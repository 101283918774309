import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  reqLoading: false
}

const mutations = {
  showLoading(state) {
    state.reqLoading = true
  },
  hideLoading(state) {
    state.reqLoading = false
  }
}

const actions = {}

const store = new Vuex.Store({
  state,
  mutations,
  actions
})

export default store
